/**
 * @module DynamicButton
 * @description Will swap between phone and cart buttons based on context
 */
import { useContext } from 'react';
import dynamic from 'next/dynamic';
import { FuelContext } from '@/providers/fuel-provider';

const PhoneButton = dynamic( () => import( '../PhoneButton' ) );
const CartButton = dynamic( () => import( '../CartButton' ) );
const LinkButton = dynamic( () => import( '../LinkButton' ) );
const SelectPlanButton = dynamic( () => import( '../SelectPlanButton' ) );

/**
 * @function DynamicButton
 * @description
 */
export const DynamicButton = ( { ...props } ) => {
  const {
    type = 'link'
  } = props;
  const { phoneHeavy, cartHeavy } = useContext( FuelContext );

  // Phone && Cart
  if ( type === 'phone' || type === 'cart' ) {
    if ( phoneHeavy === false && cartHeavy === true && type === 'phone' ) { // phoneHeavy is off, cartHeavy is on, update phone buttons
      return ( <CartButton { ...props } /> );
    } if ( phoneHeavy === true && cartHeavy === false && type === 'cart' ) { // cartHeavy is off, phoneHeavy is on, update cart buttons
      return ( <PhoneButton { ...props } /> );
    }

    if ( type === 'phone' ) {
      return ( <PhoneButton { ...props } /> );
    }

    return ( <CartButton { ...props } /> );
  }

  // When phone heavy, hide select plan buttons
  if ( type === 'selectPlan' ) {
    if ( phoneHeavy ) return null;

    return ( <SelectPlanButton { ...props } /> );
  }

  // Default Case
  return (
    <>
      <LinkButton { ...props } />
    </>
  );
};

export default DynamicButton;
